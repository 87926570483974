<template>
  <main class="DriversMap" :style="calcPosition">
    <TheSubHeader
      icon="map-pin"
      title="Mapa de Motoristas"
      page-title-tag="Drivers Map"
      type="filter"
      organizer-gap="1rem"
      :organizer-columns="organizerColumns"
    >
      <template #filter>
        <!-- Pedido -->
        <BaseTreeSelect
          v-if="filtersPermissions.orders ? filtersPermissions.orders : true"
          v-model="filter.order_number"
          name="Nº de pedido"
          label="Nº de pedido"
          placeholder="Pesquisar"
          multiple
          :enable-paste="true"
          :allow-create="true"
          no-options-text="Nº de pedido"
          @input="search(true)"
        />

        <!-- Lojista -->
        <BaseTreeSelect
          ref="lojista"
          v-model="filter.store_id"
          name="Lojista"
          label="Lojista"
          placeholder="Selecione a Loja"
          multiple
          :read-only="filtersPermissions ? !filtersPermissions.storesFilter : true" 
          :select-options="null"
          :fetch-options="'stores/fetchStoresImportAsync'"
          @input="search(true)"
        />

        <!-- Transportadora -->
        <BaseTreeSelect
          v-model="filter.transporter_id"
          name="Transportadora"
          label="Transportadora"
          placeholder="Selecione a Transportadora"
          multiple
          :read-only="filtersPermissions ? !filtersPermissions.trasporterFilter : true"
          :select-options="transporters"
          :is-loading="transporters.length === 0"
          @input="search(true)"
        />
      </template>
      <template v-if="allTags.length" #tag>
        <div class="Orders__tags">
          <FiltersTag :tags="allTags" @removeTag="removeTag" @clear="clearAllTags" />
        </div>
      </template>
    </TheSubHeader>
    <div class="DriversMap__content">
      <BaseMap ref="map" :markers="mappedTracking" :hide-details-button="filtersPermissions.orders ? !filtersPermissions.orders : false" :zoom="12" />
      <div v-if="mappedTracking.length === 0" class="DriversMap__nocontent">
        Nenhum motorista encontrado
      </div>
    </div>
  </main>
</template>

<script>
import { BaseTreeSelect } from '@/components/atoms'
import { BaseMap, FiltersTag } from '@/components/molecules'
import { TheSubHeader } from '@/components/organisms'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'DriversMap',
  components: { BaseMap, BaseTreeSelect, TheSubHeader,FiltersTag },
  data() {
    return {
      filter:{
        order_number: [],
        store_id: [],
        transporter_id: [],
      },
      allTags: [],
      timeOutUpdate: 30,
      time: null
    }
  },
  computed:{
    ...mapState({
      transporters: state => state.transporters.transporters,
      driversTracking: state => state.drivers.driversTracking,
      permissions: state => state.auth.permissions
    }),

    ...mapGetters({
      mappedTracking: 'drivers/mappedTracking',
    }),

    calcPosition(){
      return {
        '--base-positions' : this.allTags.length ?  '-141px' : '-85px'
      }
    },

    parsedTime(){
      return this.timeOutUpdate ? this.timeOutUpdate * 1000 : null
    },

    organizerColumns() {
      return this.permissions?.map?.ordersFilter ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)'
    },

    filtersPermissions() {
      return {
        orders: this.permissions?.map?.ordersFilter,
        stores: this.permissions?.map?.storesFilter,
        transporter: this.permissions?.map?.trasporterFilter
      }
    }
  },

  beforeDestroy(){
    clearTimeout(this.time)
  },

  async mounted(){
    this.setLoading(true)
    
    this.fetchTransporters()

    await this.fetchDriversTracking()
    if (this.$refs.map) {
      this.$refs.map.fitBounds()
    }

    if (this.parsedTime) this.updateMap()

    this.setLoading(false)
  },

  methods:{
    ...mapActions({
      setLoading: 'loading/setLoading',
      fetchTransporters: 'transporters/fetchTransporters',
      fetchDriversTracking: 'drivers/fetchDriversTracking',
    }),

    updateMap(){
      clearTimeout(this.time)
      this.time = setTimeout(() => {
        if (this.$route.name === 'map') {
          this.search(false)
          this.updateMap()
        } else {
          clearTimeout(this.time)
        }
      }, this.parsedTime)
    },

    updateTags(){
      this.allTags = [...this.filter.order_number, ...this.filter.store_id, ...this.filter.transporter_id]
    },

    removeTag(name, id){
      this.allTags = this.allTags.filter((tag) => tag.id !== id)
      this.filter.order_number = this.filter.order_number.filter((tag) => tag.name !== name)
      this.filter.store_id = this.filter.store_id.filter((tag) => tag.name !== name)
      this.filter.transporter_id = this.filter.transporter_id.filter((tag) => tag.name !== name)
      
      this.search(true)
    },

    clearAllTags(){
      this.allTags = []
      this.filter.order_number = []
      this.filter.store_id = []
      this.filter.transporter_id = []
      this.search(true)
    },


    async search(fitBounds){
      this.$nextTick(async () => {
        const obj = {
          order_number: this.filter.order_number,
          store_id: this.$refs.lojista.checkSelectAll ? [] : this.filter.store_id,
          transporter_id: this.filter.transporter_id
        }
        
        this.updateTags()
        if (fitBounds) {
          this.setLoading(true)
          await this.fetchDriversTracking(obj)
          this.$refs.map.fitBounds()
          this.setLoading(false)
        } else {
          await this.fetchDriversTracking(obj)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .DriversMap {
    &__content{
      height: calc(100vh - (104px - var(--base-positions)) );
      position: relative;
      margin-top: -96px;

      @media #{$mobile-view} {
        height: calc(100vh - (-26px - var(--base-positions)))
      }
    }
    &__nocontent {
      background: #fff;
      border-radius: 4px;
      padding: 20px;
      position: absolute;
      color: #333;
      top: calc(50% - 28px);
      right: calc(50% - 115px);
      z-index: 2;
      font-size: $font-size-3xsmall;
      box-shadow: 0 2px 7px 1px rgba(0,0,0,0.3);
    }
  }
</style>